export const SITES: Site[] = [
    {
        domain: 'machupicchu.viajes',
        name: 'Machu Picchu Viajes',
        logo: 'MACHUPICCHU.<span class="h-color">VIAJES</span>',
        phone: '(+51) 984 695213',
        email: 'vicccusco2006@gmail.com'
    },
    {
        domain: 'machupicchunatys.com',
        name: 'Machu Picchu Natys',
        logo: 'MACHUPICCHU<span class="h-color">NATYS</span>',
        phone: '(+51) 993 449 319',
        email: ''
    }
]

export const DEFAULT_SITE = 'machupicchu.viajes'

export interface Site {
    domain: string
    name: string
    logo: string
    phone: string
    email: string
}