import React from 'react'
import PropTypes from "prop-types"
import { AnimateSharedLayout } from "framer-motion"
import 'ionicons';

/* Theme variables */
import '../styles/grid.min.css';
import '../styles/variables.css';
import '../styles/custom-colors.css';
import '../styles/globals.css';
import '../styles/custom.css';

import { StoreProvider } from '../store/store';

const Layout = ({ children, location }: any) => {

    return (
		<StoreProvider>
			<div id="main" className="main">
				<AnimateSharedLayout>{children}</AnimateSharedLayout>
				<div className="bottom">
					<div className="bottom-left">© {new Date().getFullYear()}</div>
					<div className="bottom-right"><a href="#main" className="back-btn">Volver arriba</a></div>
				</div>
			</div>
		</StoreProvider>
	)
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
}

export default Layout
