import React, {createContext, useReducer} from 'react';
import { DEFAULT_SITE, Site, SITES } from '../settings/settings';

export enum actions {
    SET_SITE = 'set_site'
}

interface StoreAction {
    type: string
    payload: any
}

interface StoreState {
    store: Store
    dispatch: React.Dispatch<StoreAction>
}

export interface Store {
    site: Site
}

const storeData: any = {
    site: SITES.find(s => s.domain === DEFAULT_SITE)
}

const StoreContext = createContext(storeData)
const { Provider } = StoreContext

const StoreProvider = ({children}: any) => {
    const [state, dispatch] = useReducer((state: Store, action: StoreAction) => {
        switch(action.type) {
            // app actions
            case actions.SET_SITE:
                return {...state, site: action.payload} 
            default:
                throw new Error()
        }
    }, storeData)

    return <Provider value={{ state, dispatch }}>{children}</Provider>
}

export { StoreContext, StoreProvider }